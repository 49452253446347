import {
	Box,
	Button,
	Card,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import config from "../../config";
import { Add, Clear, CloudUpload, Send, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import profileApi from "../../api/profile";
import { showError, showSuccess } from "../../App";
import fileApi from "../../api/file";
import campaignApi from "../../api/campaign";
import CampaignFields from "./CampaignFields";
import { useNavigate } from "react-router-dom";

export default function AddCampaign() {
	const [data, setData] = useState({
		title: "",
		description: "",
		url: "",
		image: "",
		email_body: "",
	});
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const res = await campaignApi.createCampaign(data);
		if (res.success) {
			showSuccess("Campaign added successfully");
			navigate("/campaign");
		} else {
			showError("Can't add campaign");
		}
		setLoading(false);
	};
	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-10 justify-center w-full max-w-4xl"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<CampaignFields
				data={data}
				setData={setData}
				handleSubmit={handleSubmit}
			/>
		</Box>
	);
}
