import {
	Box,
	Button,
	Card,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import config from "../../config";
import {
	Add,
	Clear,
	CloudUpload,
	ContentCopy,
	Send,
	Upload,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import profileApi from "../../api/profile";
import { showError, showSuccess } from "../../App";
import fileApi from "../../api/file";
import campaignApi from "../../api/campaign";
import CampaignFields from "./CampaignFields";
import { useLocation } from "react-router-dom";

export default function EditCampaign() {
	const [data, setData] = useState({
		title: "",
		description: "",
		url: "",
		image: "",
		email_body: "",
	});
	const [loading, setLoading] = useState(false);
	const [id, setId] = useState(null);
	const location = useLocation();
	useEffect(() => {
		setId(location.hash.substring(1));
	}, [location]);

	const fetchCampaign = async () => {
		const response = await campaignApi.getCampaign(id);
		if (response.success) {
			setData(response.data);
		}
	};
	useEffect(() => {
		if (id) {
			fetchCampaign();
		}
	}, [id]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const res = await campaignApi.updateCampaign(data.id, data);
		if (res.success) {
			showSuccess("Campaign updated successfully");
		} else {
			showError("Can't update campaign");
		}
		setLoading(false);
	};
	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-5 justify-center w-full max-w-4xl"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<Card className="flex items-center gap-5 w-full p-5">
				<Box className="flex flex-row justify-between w-full items-center">
					<Typography variant="h6">
						{(process.env.PUBLIC_URL
							? process.env.PUBLIC_URL.replace(
									"dolphin",
									"click"
							  ).replace("http://", "https://")
							: "https://click.priyolab.net") +
							"/" +
							data.id}
					</Typography>
					<IconButton
						onClick={() =>
							navigator.clipboard.writeText(
								(process.env.PUBLIC_URL
									? process.env.PUBLIC_URL.replace(
											"dolphin",
											"click"
									  ).replace("http://", "https://")
									: "https://click.priyolab.net") +
									"/" +
									data.id
							)
						}
					>
						<ContentCopy />
					</IconButton>
				</Box>
			</Card>
			<CampaignFields
				data={data}
				setData={setData}
				handleSubmit={handleSubmit}
				mode="edit"
			/>
		</Box>
	);
}
