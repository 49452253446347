import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { useTheme } from "@emotion/react";

const EmailEditor = ({ value, onChange }) => {
	const editor = useRef(null);
	const [content, setContent] = useState("");
	const theme = useTheme();
	const options = [
		"bold",
		"italic",
		"|",
		"ul",
		"ol",
		"|",
		"font",
		"fontsize",
		"|",
		"outdent",
		"indent",
		"align",
		"|",
		"hr",
		"|",
		"fullsize",
		"brush",
		"|",
		"table",
		"link",
		"|",
		"undo",
		"redo",
	];

	const copyStringToClipboard = function (str) {
		var el = document.createElement("textarea");
		el.value = str;
		el.setAttribute("readonly", "");
		el.style = { position: "absolute", left: "-9999px" };
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
	};

	const buttons = [
		"undo",
		"redo",
		"|",
		"bold",
		"strikethrough",
		"underline",
		"italic",
		"|",
		"superscript",
		"subscript",
		"|",
		"align",
		"|",
		"ul",
		"ol",
		"outdent",
		"indent",
		"|",
		"font",
		"fontsize",
		"brush",
		"paragraph",
		"|",
		"image",
		"link",
		"table",
		"|",
		"hr",
		"eraser",
		"copyformat",
		"|",
		"fullsize",
		"selectall",
		"print",
		"|",
		"source",
		"|",
		{
			name: "copyContent",
			tooltip: "Copy HTML to Clipboard",
			iconURL: "/images/copy.png",
			exec: function (editor) {
				let html = editor.value;
				copyStringToClipboard(html);
			},
		},
	];
	const config = useMemo(
		() => ({
			readonly: false, // all options from https://xdsoft.net/jodit/docs/,
			placeholder: "Start typings...",
			defaultActionOnPaste: "insert_as_html",
			defaultLineHeight: 1.5,
			enter: "div",
			// options that we defined in above step.
			buttons: buttons,
			buttonsMD: buttons,
			buttonsSM: buttons,
			buttonsXS: buttons,
			statusbar: false,
			sizeLG: 900,
			sizeMD: 700,
			sizeSM: 400,
			toolbarAdaptive: false,
			theme: theme.palette.mode,
		}),
		[theme.palette.mode]
	);

	return (
		<JoditEditor
			ref={editor}
			value={value}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={(newContent) => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
			onChange={(newContent) => {}}
		/>
	);
};

export default EmailEditor;
